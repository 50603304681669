import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import {RootState} from '../rootReducer'
import {persistReducer} from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session';
import {IHotelCruise,IHotelCruiseState} from "../../models/hotelCruiseLine";
import {ICart} from "../../models/cart";
import {closeBackdrop, openBackdrop} from "../../store/app/app";
const api = process.env.REACT_APP_API_URL
const initialState: IHotelCruiseState = {
    hotelCruiseLines: [],
    loading: false,
}

export const listCruiseLine = createAsyncThunk<IHotelCruise[],
    {type:string,date:string },
    {
        rejectValue: unknown
    }>(
    'hotelCruise/list',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.get(`${api}/hotel-cruise?type=${data.type}&date=${data.date}`);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {

            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)
export const hotelCruiseLineSlice = createSlice({
    initialState,
    name: 'hotelCruise',
    extraReducers: builder => {
        builder
            .addCase(listCruiseLine.pending, state => {
                state.loading = true
            })
            .addCase(listCruiseLine.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.hotelCruiseLines=payload
                state.loading = false
            })
            .addCase(listCruiseLine.rejected, (state, action) => {
                state.loading = false
            })
    },
    reducers: {},
})

export const hotelCruiseSelector = (state: RootState) => {
    return {
        hotelCruiseLines: state.hotelCruiseLine.hotelCruiseLines,
        loadingHotelCruiseLine: state.hotelCruiseLine.loading,
    };
};

export const {} = hotelCruiseLineSlice.actions;
export const HotelCruiseLineReducer = persistReducer({
    storage: storageSession,
    key: 'hotelCruiseLine',
}, hotelCruiseLineSlice.reducer)
