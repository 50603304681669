import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {AppSelector, closeBackdrop, openBackdrop} from "../../store/app/app";
import {useAppDispatch} from "../../store/store";
import {cartPaymentSelector, cartResumeSelector, cartSelector, fillFinalBook} from '../../store/cart/cart'
import {useNavigate} from "react-router-dom";
import {PayPalButtons, SCRIPT_LOADING_STATE, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import {useTranslation} from "react-i18next";
import {clientSelector} from "../../store/clientInfo/clientInfo";

const api = process.env.REACT_APP_API_URL
export const PaymentButton = () => {
    let navigate = useNavigate();
    let dataPayment= useSelector(cartPaymentSelector);
    const {
        backdrop
    } = useSelector(AppSelector);
    const CartSelectorResume = useSelector(cartResumeSelector);
    const CartSelector = useSelector(cartSelector);
    const ClientSelector = useSelector(clientSelector);
    let {
        folio,
    } = ClientSelector;
    let {
        resume_currency,
    } = CartSelectorResume;
    let {
        payment_method
    } = CartSelector;
    const dispatchApp = useAppDispatch();
    const { i18n } = useTranslation();
    const [{ options,isPending,isResolved}, dispatch] = usePayPalScriptReducer();
    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: resume_currency,
            },
        });

    },[resume_currency,payment_method]);

    useEffect(() => {

        if(!isResolved){
            dispatch({
                type: "setLoadingStatus",
                value: SCRIPT_LOADING_STATE.INITIAL,
            });
        }

    },[resume_currency]);

    const createOrder = () => {
        dispatchApp(openBackdrop())
        return fetch(`${api}/checkout/storePaypal`, {
            method: "POST",
            body: JSON.stringify(dataPayment),
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => {
                return response.json()
            })
            .then((order) => {
                if(order.id) {
                    dispatchApp(closeBackdrop())
                    return order.id
                }  else {
                    const error=order?.error
                    // dispatchApp(openNotifier({variant: "error", open: true, message:error??'Ops, something unexpected happened, please try again later!'}))
                    dispatchApp(closeBackdrop())
                }
            })
            .catch((err) => {

                dispatchApp(closeBackdrop())
            });
    };
    const onApprove = (data: any) => {
        dispatchApp(openBackdrop())
        const {orderID,paymentSource,payerID} = data
        return fetch(`${api}/checkout`, {
            method: "PUT",
            body: JSON.stringify({
                payment_id: orderID,
                payer_id: payerID,
                folio:folio,
                language_ticket:i18n.resolvedLanguage,
                payment_source: paymentSource
            }),
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => {
                return response.json()
            })
            .then((responseData) => {
                const {
                    invoice_number,
                } = responseData;
                if(invoice_number){
                    dispatchApp(fillFinalBook(responseData))
                    dispatchApp(closeBackdrop())
                    // navigate(`../../${lang}/final-book/${folio}/${full_name}/`, {replace: true,state:{payment:true}})
                    // @ts-ignore
                    if (window.dataLayer) {
                        // @ts-ignore
                        window.dataLayer.push({
                            event: 'Page-Final-Book',
                        });
                    }
                    window.sessionStorage.removeItem("persist:app");
                    window.sessionStorage.removeItem("persist:client");
                    window.sessionStorage.removeItem("persist:hotelCruiseLine");
                    window.sessionStorage.removeItem("persist:package");
                    window.sessionStorage.removeItem("persist:cart");
                    window.sessionStorage.clear();
                } else {
                    dispatchApp(closeBackdrop())
                    const error=responseData?.error
                    // dispatchApp(openNotifier({variant: "error", open: true, message:error??'Ops, something unexpected happened, please try again later!'}))
                }

            })
            .catch((err) => {
                dispatchApp(closeBackdrop())
            });

    }
    // @ts-ignore
    return (
        <div>
               <PayPalButtons
                    createOrder={() => createOrder()}
                    onCancel={()=>{
                        if(backdrop){
                            dispatchApp(closeBackdrop())
                        }
                    }}
                    onError={()=> {
                        if(backdrop){
                            dispatchApp(closeBackdrop())
                        }

                    }}
                    onInit={(data, actions)=>{

                        if(data?.correlationID!==''){
                            dispatch({
                                type: "setLoadingStatus",
                                value: SCRIPT_LOADING_STATE.RESOLVED,
                            });
                            dispatchApp(closeBackdrop())
                        }


                    }}
                    onApprove={(OnApproveData,actions) => {
                        return onApprove(OnApproveData);
                    }
                    }
                />



        </div>
    );
};
