import * as React from 'react';
import {useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {clientSelector} from '../../store/clientInfo/clientInfo';
import {close_modal_addon} from "../../store/app/app";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
import {  useParams } from 'react-router-dom';
import {unwrapResult} from '@reduxjs/toolkit';
export const ErrorPayment=()=> {

    const { t } = useTranslation();
    let { folio } = useParams();
    const ClientSelector = useSelector(clientSelector);
    let {
        client:{
        idPayment
        }
    } = ClientSelector;

    const dispatchApp = useAppDispatch();
            return (
        <>
            <div className="contGracias">
                <h1>{t('folio'??'',{ ns: namespaces.error_open_pay})??''} {folio} </h1>
                <p>{t('an_error'??'',{ ns: namespaces.error_open_pay})??''}  <strong>{t('no_charge'??'',{ ns: namespaces.error_open_pay})??''}</strong>  </p>
            </div>
        </>
    );
}

