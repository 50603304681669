import React,{useEffect } from 'react';
import Stepper from '@mui/material/Stepper';
import {  useSearchParams } from 'react-router-dom';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Container} from '@mui/material';
import Stack from '@mui/material/Stack';
import {ConnectorCustom} from 'src/components/stepConnector/stepConnector';
import {StepIcon} from 'src/components/stepIcon/stepIcon';
import {useNavigate} from "react-router-dom";
import {CardPackage} from '../../components/cardPackage/cardPackage';
import {PurchaseDetail} from '../../components/purchaseDetail/purchaseDetail';
import { useAppDispatch } from '../../store/store'
import {AppSelector, AppValidateSelector, changeUrlFirst,open_modal_addon,close_modal_addon,open_modal_addon_true,changeOriginSale} from '../../store/app/app'
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {
    addItem,
    lisItems,
    cartSelector,
    changeCurrency,
    finalBookSelector,
    cartResumeSelector
} from '../../store/cart/cart';
import {fillClient,changeValidForm,clientSelector,getClientInfo,fillFolioDate,fillFolio,fillDate} from '../../store/clientInfo/clientInfo';
import {AddOns} from "../../components/addOns/addOns";
import {useSelector} from 'react-redux';
import {IPackagesItems} from "../../models/cart";
import {CardPackagePayment} from '../cardPackagePayment/cardPackagePayment'
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import {useLocation} from 'react-router-dom';
import FormControlLabel from "@mui/material/FormControlLabel";
import {unwrapResult} from '@reduxjs/toolkit';
import Radio from "@mui/material/Radio";
import {hotelCruiseSelector, listCruiseLine} from "../../store/hotelCruiseLine/hotelCruiseLine";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Select from '@mui/material/Select';
import {IHotelCruise} from "../../models/hotelCruiseLine";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import {useFormik} from "formik";
import {IClient, InitialClient} from "../../models/client";
import {FinalBook} from "../../components/finalBook/finalBook";
import {openNotifier} from "../../store/notifier/notifier";
import {changeSkuAddon,packageSelector} from "../../store/packages/package";
import {AxiosError} from "axios";
import { useTranslation } from 'react-i18next';
import { namespaces } from "../../i18n/i18n.constants";

const steps = ['Check your cart', 'Customer information', 'Add-ons for your fun', 'Payment process', 'Confirmation'];
export const StepperComponent = () => {
    let schemaShoppingCart  = yup.object().shape({
        email:  yup.string().matches(/^(?!(test|tester|dev|development)\b)/i, "Invalid email").email("Invalid email format").required(),
        name:  yup.string().required("Name is required"),
        last_name: yup.string().required("Lastname is required"),
        point_sale_id: yup.number().required("hotel cruise is required").positive('hotel cruise is required'),
        terms_conditions: yup.boolean().required("The terms and conditions must be accepted.")
            .oneOf([true], "The terms and conditions must be accepted."),
        phone_number: yup.number().required("phone number is required"),
        state_province: yup.string().required("state is required"),
        country: yup.string().required("country is required"),
        city: yup.string().required("city is required"),
        arrive_type:yup.string().required("you must select a type of arrival")
    });
    const HotelCruiseSelector = useSelector(hotelCruiseSelector);
    const appValidateSelector = useSelector(AppValidateSelector);
    const PackageSelector = useSelector(packageSelector);
    const CartSelectorResume = useSelector(cartResumeSelector);

    let {
        resume_cart_contain_at_least_one_service
    } = CartSelectorResume;
    let {
        sku_addon
    } = PackageSelector;
    let {
        hotelCruiseLines,
        loadingHotelCruiseLine
    } = HotelCruiseSelector;
    let {
        validate_cart,
        validate_form,
    } = appValidateSelector;
    const [activeStep, setActiveStep] = React.useState(0);
    const location = useLocation();

    const dispatch = useAppDispatch();
    const CartSelector = useSelector(cartSelector);
    const ClientSelector = useSelector(clientSelector);
    const navigate = useNavigate();
    const appSelector = useSelector(AppSelector);
    const {url_with_param} = appSelector;
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            },
        },
    };
    let {
        cookie,
        cart: {
            items,
            resume: {
                promotion,
                coupon
            }
        }
    } = CartSelector;
    let {
        client,
        folio,
        date_operation
    } = ClientSelector;
    const FinalBookSelector = useSelector(finalBookSelector);
    let {
        invoice_number,
        transaction_status
    } = FinalBookSelector;
    const { t,i18n } = useTranslation();
    // const stepsTranslate=;
    let [searchParams] = useSearchParams();
    const {
        setFieldValue,
        errors,
        touched,
        submitForm,
        values,
        setValues,
    } = useFormik<IClient>({
        initialValues: InitialClient,
        validationSchema:schemaShoppingCart,
        onSubmit: (values: IClient, formikHelpers) => {
            if(folio===''){
                dispatch(fillClient(values))
            }
            dispatch(changeValidForm())
            navigate("../addons", {replace: true})
            setActiveStep(2);
        },
    })
    const handleNext = () => {
        if(activeStep ===0 && validate_cart){
            setActiveStep(1);
            navigate("../client-information", {replace: true})
        }
        if(activeStep===1){

        }
        if(activeStep===2 && validate_form && validate_cart){
            navigate("../payment-method", {replace: true})
             setActiveStep(3);

        }
        if(activeStep===3){
            // setActiveStep(4);
        }



    };
    useEffect(() => {

        if(location.pathname==='/add-product' && !searchParams.get('addon')){
            const newDay = new Date();
            const today = newDay.getFullYear() + '-' + (((newDay.getMonth() + 1) < 10) ? '0' : '') + (newDay.getMonth() + 1) + '-' + ((newDay.getDate() < 10) ? '0' : '') + newDay.getDate();
                if(searchParams.get('id')){
                    dispatch(addItem({package:{
                            date_operation:searchParams.get('date_operation')??today,
                            adult:+searchParams.get('adult')!??1,
                            children:+searchParams.get('children')!??0,
                            currency:searchParams.get('currency')!??'MXN',
                        },
                        currency:searchParams.get('currency')!??'MXN',
                        id:searchParams.get('id')??'',cookie:cookie}))
                    dispatch(fillDate({date:searchParams.get('date_operation')??today}))
                    dispatch(changeUrlFirst(`id=${searchParams.get('id')??''}&date_operation=${searchParams.get('date_operation')??today}&adult=${searchParams.get('adult')!??1}&children=${+searchParams.get('children')!??0}&currency=${searchParams.get('currency')??'MXN'}&origin_sale=${searchParams.get('origin_sale')??'1'}`));
                } else {
                        if(items.lengh>0){
                            dispatch(lisItems({}))
                        } else {

                        }
                }

        }
        if(searchParams.get('addon') && !searchParams.get('folio')){
            dispatch(open_modal_addon())
            dispatch(changeSkuAddon(searchParams.get('id')??''))
        }
        if(searchParams.get('addon') && searchParams.get('folio')){
            dispatch(open_modal_addon_true())
            dispatch(changeSkuAddon(searchParams.get('id')??''))
            if(searchParams.get('folio')){
                dispatch(fillFolio({folio:searchParams.get('folio')??''}))
                dispatch(getClientInfo({folio:searchParams.get('folio')??''}))
            }
        }
        if(searchParams.get('origin_sale') ){
            dispatch(changeOriginSale(searchParams.get('origin_sale')??''))
        }

    }, []);

    useEffect(() => {
        const originSale = searchParams.get('origin_sale');
        originSale && (originSale === '3' || originSale === '4' ?  i18n.changeLanguage('en').then() :  i18n.changeLanguage('es').then())
    }, [searchParams.get('origin_sale') ]);

    useEffect(() => {
        if(location.pathname==='/client-information' && searchParams.get('folio') && folio===''){
            dispatch(getClientInfo({folio:searchParams.get('folio')??''})).then(unwrapResult).then((result: any) => {
                dispatch(fillFolioDate({folio:searchParams.get('folio')??'',date_operation:result?.dateOperation}))
                dispatch(changeCurrency(result?.currency))
                setActiveStep(1);
                dispatch(openNotifier({variant: "success", open: true, message: `the reservation number is valid ${searchParams.get('folio')}`}))
            }).catch((result: AxiosError) => {
                // @ts-ignore
                dispatch(openNotifier({variant: "error", open: true, message: `an error occurred with the reservation number,try with a valid one`}))
            })
        }
    }, [location.pathname]);

    useEffect(() => {

    }, [location.pathname]);


    useEffect(() => {
        if (client.name) {
            setValues({
                name:client?.name,
                email:client?.email,
                last_name: client?.last_name,
                point_sale_id: client?.point_sale_id,
                terms_conditions:  client?.terms_conditions,
                phone_number: client?.phone_number,
                state_province:  client?.state_province,
                country: client?.country,
                city: client?.city,
                arrive_type:  client?.arrive_type
            })
        }
    }, [client]);
    useEffect(() => {
        if (client.arrive_type!=='' && searchParams.get('folio')!==null) {
            // @ts-ignore
            dispatch(listCruiseLine({type:client.arrive_type,date:date_operation}))
        }
    }, [client.arrive_type]);
    useEffect(() => {
        if (client.arrive_type!=='' && searchParams.get('folio')!==null) {
            // @ts-ignore
            dispatch(listCruiseLine({type:client.arrive_type,date:date_operation}))
        }

    }, []);

    useEffect(() => {

            if(invoice_number && validate_cart && validate_form){
                navigate("../final-book", {replace: true})
                window.scrollTo(0, 0)
                setActiveStep(4);
            }
    }, [invoice_number]);
    useEffect(() => {
        let route=url_with_param===''?'../add-product':`../add-product?${url_with_param}`
        let routeClientName=folio?`../client-information?folio=${folio}`:'../client-information';
        if(location.pathname!=='/add-product'){

        }

        if((location.pathname==='/client-information'|| location.pathname==='addons') && (!validate_cart && searchParams.get('folio')===null)){
            setActiveStep(1);
            navigate(routeClientName, {replace: true})
        }
        if(location.pathname==='/payment-method' && (!validate_cart || !validate_form)){
            setActiveStep(0);
            navigate(route, {replace: true})
        }
        if(location.pathname==='/final-book' && (!validate_cart || !validate_form)){
            // setActiveStep(0);
            // navigate(route, {replace: true})
        }

    }, [location.pathname]);
    useEffect(() => {
        switch (location.pathname) {
            case '/add-product':
                //
                break;
            case '/client-information':
                if(activeStep===1){

                }else{
                    if(validate_cart){
                        setActiveStep(1);
                    }
                }
                break;
            case '/addons':
                if(activeStep===2){

                }else{
                    if(validate_form && validate_cart){
                        setActiveStep(2);
                    }

                }
                break;
            case '/payment-method':
                if(activeStep===3){

                }else{

                    if(validate_form && validate_cart){
                        setActiveStep(3);
                    }
                }
                break;
            case '/final-book':
                break;
            default:
                navigate("../add-product", {replace: true})
                setActiveStep(0);
                break;
        }

    }, [location.pathname]);

    // @ts-ignore
    return (
        <Container maxWidth="xl" className="mt-30">
            <Grid container spacing={2} columns={16}>
                <Grid xs={16}>
                    <Typography variant="h6">{t('shopping_cart'??'',{ ns: namespaces.header})}</Typography>
                    <Stack sx={{width: '100%'}} spacing={4} className="mt-30 mb-30 stepper">
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ConnectorCustom/>}>
                            {steps.map((label,index) => {
                                return (<Step key={label} index={index} onClick={(event)=>{
                                        if (index===0&&(folio==='' ||sku_addon!=='')&&transaction_status===''){
                                            let route=url_with_param===''?'../add-product':`../add-product?${url_with_param}`
                                            navigate(route, {replace: true})
                                            setActiveStep(index);
                                        }
                                        if(index===1 &&transaction_status===''&& (validate_cart || folio!=='')){
                                            let routeClientName=folio?`../client-information?folio=${folio}`:'../client-information?';
                                            navigate(routeClientName, {replace: true})
                                            setActiveStep(index);
                                        }
                                        if(index===2&&transaction_status==='' && validate_form && validate_cart){
                                            navigate("../addons", {replace: true})
                                            setActiveStep(index);
                                        }
                                        if(index===3&&transaction_status==='' && validate_form && validate_cart){
                                            navigate("../payment-method", {replace: true})
                                            setActiveStep(index);
                                        }
                                        if(index===4){

                                        }

                                    }}>
                                        <StepLabel StepIconComponent={StepIcon}>{t(label??'',{ ns: namespaces.steps})}</StepLabel>
                                    </Step>
                                )
                            })
                            }
                        </Stepper>
                    </Stack>
                </Grid>

                {
                    activeStep === 0 && <Grid xs={16} sm={16} md={11}>

                        {

                            items.map((item: IPackagesItems,index:number)=>{
                                return<React.Fragment key={`fragment-${index}`}>
                                    <CardPackage
                                        key={index}
                                        type={item.type}
                                        id_tour={item.sku}
                                        paragrahp_1={item.name}
                                        date_reservation={item.date_operation}
                                        adult={+item.adult}
                                        amount={+item.amount}
                                        child={+item.children}
                                        transfer={item.idTransporte}
                                    />
                                </React.Fragment>

                            })
                        }

                    </Grid>
                }


                {
                    activeStep === 1 && <Grid md={11} xs={16}>
                        <Grid container spacing={2} columns={12} >
                            <form  onSubmit={submitForm} className="form-login">
                                <Grid md={12} display="flex" justifyContent="start" alignItems="flex-end">
                                    <div className="AshleySemBold txt-25">{t('please_enter_your_information_correctly'??'',{ ns: namespaces.client_information})}</div>
                                </Grid>
                                <Grid md={4} xs={16}>
                                    <FormLabel id="label-id-arrive">{t('select_an_arrival_method'??'',{ ns: namespaces.mistakes})}</FormLabel>
                                    <FormControl error={!!errors.arrive_type && !!touched.arrive_type}>
                                        <RadioGroup
                                            aria-labelledby="label-id-arrive"
                                            name="radio-group-arrive"
                                            value={values.arrive_type}
                                        >
                                            <Grid container display="flex"  columns={12} >
                                                <Grid md={6} >
                                                    <FormControlLabel value="2" className="inpRadio" control={<Radio/>} label={t('hotel'??'',{ ns: namespaces.client_information})}
                                                                      onChange={(event)=>{
                                                                          setFieldValue("arrive_type", '2')
                                                                          // @ts-ignore
                                                                          dispatch(listCruiseLine({type:event.currentTarget.value,date:date_operation}))
                                                                      }}
                                                    />
                                                </Grid>
                                                <Grid md={6} alignSelf="self-end" justifySelf="left">
                                                    <FormControlLabel value="1" className="inpRadio" control={<Radio />}
                                                                      onChange={(event)=>{
                                                                          setFieldValue("arrive_type", '1')
                                                                          // @ts-ignore
                                                                          dispatch(listCruiseLine({type:event.currentTarget.value,date:date_operation}))
                                                                      }}
                                                                      label={t('cruise_line'??'',{ ns: namespaces.client_information})} />
                                                </Grid>
                                                {
                                                    (errors.arrive_type && !!touched.arrive_type) && <FormHelperText className="txtError">{t('select_an_arrival_method'??'',{ ns: namespaces.mistakes})}</FormHelperText>
                                                }
                                            </Grid>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid md={4} xs={16} display="flex" alignSelf="self-end" justifySelf="left">
                                    <FormControl >
                                        <Grid container display="flex" columns={12} >
                                            <Grid md={12} xs={16}>
                                                <FormLabel id="label-id-select-hotel-cruise-line"> {t('select_hotel_cruise'??'',{ ns: namespaces.client_information})}</FormLabel>
                                                <FormControl sx={{mt: 1, minWidth: '100%'}} variant="standard" error={!!errors.point_sale_id && !!touched.point_sale_id}>

                                                    {
                                                        loadingHotelCruiseLine ?
                                                            <Box sx={{ width: '100%' }}>
                                                                <LinearProgress />
                                                            </Box> :
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="point_sale_id"
                                                                name={'point_sale_id'}
                                                                value={values?.point_sale_id }
                                                                MenuProps={MenuProps}
                                                                onChange={(event, child)=>{
                                                                    setFieldValue("point_sale_id",event.target.value??'')
                                                                }}
                                                            >
                                                                <MenuItem key={0} value={0}>

                                                            </MenuItem>)
                                                                {

                                                                    hotelCruiseLines?.filter(Boolean).map((hotelCruise:IHotelCruise)=>
                                                                        <MenuItem key={hotelCruise.id} value={hotelCruise.id}>
                                                                            {hotelCruise.name}
                                                                        </MenuItem>)
                                                                }

                                                            </Select>

                                                    }
                                                    {(errors.point_sale_id && !!touched.point_sale_id) && <FormHelperText>  {t('Select_hotel_cruise_line'??'',{ ns: namespaces.mistakes})}</FormHelperText>}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Grid>

                                <Grid md={4} xs={16} display="flex" alignSelf="self-end" justifySelf="left" className="inputwidth">
                                    <FormControl className="inputwidth FormControl">
                                        <Grid container display="flex"  columns={12} >
                                            <Grid md={12} sm={16} xs={16} >
                                                <FormLabel id="label-id-select-hotel-cruise-line">  {t('name'??'',{ ns: namespaces.client_information})}</FormLabel>
                                                <FormControl sx={{mt: 1, minWidth: '100%'}} >
                                                    <TextField
                                                        id="name"
                                                        variant="standard"
                                                        name={"name"}
                                                        error={!!errors.name && !!touched.name}
                                                        helperText={(errors.name && touched.name)?t('you_must_enter_a_name'??'',{ ns: namespaces.mistakes}):''}
                                                        onChange={(event)=>{
                                                            setFieldValue("name", event.currentTarget.value??'')
                                                        }}
                                                        value={values.name}

                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                                <Grid md={4} xs={16} display="flex" alignSelf="self-end" justifySelf="left" className="inputwidth">
                                    <FormControl className="inputwidth FormControl">
                                        <Grid container display="flex"  columns={12} >
                                            <Grid md={12} sm={16} xs={16}>
                                                <FormLabel id="label-id-select-hotel-cruise-line">{t('last_name'??'',{ ns: namespaces.client_information})}</FormLabel>
                                                <FormControl sx={{mt: 1, minWidth: '100%'}} >
                                                    <TextField
                                                        id="lastname"
                                                        variant="standard"
                                                        name={"last_name"}
                                                        error={!!errors.last_name && !!touched.last_name}
                                                        helperText={(errors.last_name && touched.last_name)?t('you_must_enter_your_last_name'??'',{ ns: namespaces.mistakes}):''}
                                                        onChange={(event)=>{
                                                            setFieldValue("last_name", event.currentTarget.value??'')
                                                        }}

                                                        value={values.last_name}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                                <Grid md={4} xs={16} display="flex" alignSelf="self-end" justifySelf="left" className="inputwidth">
                                    <FormControl className="inputwidth FormControl">
                                        <Grid container display="flex"  columns={12} >
                                            <Grid md={12} sm={16} xs={16}>
                                                <FormLabel id="label-id-select-hotel-cruise-line">{t('email'??'',{ ns: namespaces.client_information})}</FormLabel>
                                                <FormControl sx={{mt: 1, minWidth: '100%'}}  >
                                                    <TextField
                                                        id="email"
                                                        error={!!errors.email && !!touched.email}
                                                        helperText={(errors.email && touched.email)?t('you_must_enter_a_valid_email'??'',{ ns: namespaces.mistakes}):''}
                                                        variant="standard"
                                                        name={"email"}
                                                        onChange={(event)=>{
                                                            setFieldValue("email", event.currentTarget.value??'')
                                                        }}
                                                        value={values.email}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                                <Grid md={4} xs={16} display="flex" alignSelf="self-end" justifySelf="left" className="inputwidth">
                                    <FormControl  className="inputwidth FormControl">
                                        <Grid container display="flex"  columns={12} >
                                            <Grid md={12}  sm={16} xs={16}>
                                                <FormLabel id="label-id-select-hotel-cruise-line">{t('phone_number'??'',{ ns: namespaces.client_information})}</FormLabel>
                                                <FormControl sx={{mt: 1, minWidth: '100%'}} >
                                                    <TextField
                                                        id="phone_number"
                                                        error={!!errors.phone_number && !!touched.phone_number}
                                                        helperText={(errors.phone_number && touched.phone_number)?t('you_must_enter_a_phone_number'??'',{ ns: namespaces.mistakes}):''}
                                                        name={"phone_number"}
                                                        onChange={(event)=>{
                                                            setFieldValue("phone_number", event.currentTarget.value??'')
                                                        }}
                                                        value={values.phone_number}
                                                        variant="standard" />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                                <Grid md={4} xs={16} display="flex" alignSelf="self-end" justifySelf="left" className="inputwidth">
                                    <FormControl className="inputwidth FormControl" >
                                        <Grid container display="flex"  columns={12} >
                                            <Grid md={12} sm={16} xs={16}>
                                                <FormLabel id="label-id-select-hotel-cruise-line">{t('state_province'??'',{ ns: namespaces.client_information})}</FormLabel>
                                                <FormControl sx={{mt: 1, minWidth: '100%'}} >
                                                    <TextField
                                                        id="state_province"
                                                        error={!!errors.state_province && !!touched.state_province}
                                                        helperText={(errors.state_province && touched.state_province)?t('you_must_enter_a_phone_number'??'',{ ns: namespaces.mistakes}):''}
                                                        variant="standard"
                                                        name={"state_province"}
                                                        onChange={(event)=>{
                                                            setFieldValue("state_province", event.currentTarget.value??'')
                                                        }}
                                                        value={values.state_province}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                                <Grid md={4} xs={16} display="flex" alignSelf="self-end" justifySelf="left" className="inputwidth">
                                    <FormControl className="inputwidth FormControl">
                                        <Grid container display="flex"  columns={12}>
                                            <Grid md={12} sm={16} xs={16}>
                                                <FormLabel id="label-id-select-hotel-cruise-line">{t('country'??'',{ ns: namespaces.client_information})}</FormLabel>
                                                <FormControl sx={{mt: 1, minWidth: '100%'}} variant="standard" error={!!errors.country && !!touched.country}>

                                                    <Select
                                                        labelId="demo-simple-select-standard-label-country"
                                                        id="country"
                                                        name={'country'}
                                                        value={values.country}
                                                        MenuProps={MenuProps}
                                                        onChange={(event, child)=>{
                                                            setFieldValue("country",event.target.value??'')
                                                        }}
                                                    >
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value=""></MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="US">United States</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MX">Mexico</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="DE">Germany</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="IT">Italy</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BR">Brazil</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="CA">Canada</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="AL">Albania</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="DZ">Algeria</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="AD">Andorra</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="AO">Angola</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="AI">Anguilla</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="AG">Antigua &amp; Barbuda</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="AR">Argentina</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="AM">Armenia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="AW">Aruba</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="AU">Australia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="AT">Austria</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="AZ">Azerbaijan</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BS">Bahamas</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BH">Bahrain</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BB">Barbados</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BY">Belarus</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BE">Belgium</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BZ">Belize</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BJ">Benin</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BM">Bermuda</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BT">Bhutan</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BO">Bolivia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BA">Bosnia &amp; Herzegovina</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BW">Botswana</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="VG">British Virgin Islands</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BN">Brunei</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BG">Bulgaria</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BF">Burkina Faso</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="BI">Burundi</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="KH">Cambodia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="CM">Cameroon</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="CV">Cape Verde</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="KY">Cayman Islands</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="TD">Chad</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="CL">Chile</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="CN">China</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="C2">China Worldwide</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="CO">Colombia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="KM">Comoros</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="CG">Congo - Brazzaville</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="CD">Congo - Kinshasa</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="CK">Cook Islands</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="CR">Costa Rica</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="CI">Côte D’ivoire</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="HR">Croatia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="CY">Cyprus</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="CZ">Czech Republic</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="DK">Denmark</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="DJ">Djibouti</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="DM">Dominica</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="DO">Dominican Republic</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="EC">Ecuador</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="EG">Egypt</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SV">El Salvador</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="ER">Eritrea</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="EE">Estonia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="ET">Ethiopia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="FK">Falkland Islands</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="FO">Faroe Islands</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="FJ">Fiji</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="FI">Finland</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="FR">France</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="GF">French Guiana</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="PF">French Polynesia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="GA">Gabon</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="GM">Gambia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="GE">Georgia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="GI">Gibraltar</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="GR">Greece</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="GL">Greenland</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="GD">Grenada</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="GP">Guadeloupe</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="GT">Guatemala</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="GN">Guinea</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="GW">Guinea-Bissau</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="GY">Guyana</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="HN">Honduras</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="HK">Hong Kong Sar China</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="HU">Hungary</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="IS">Iceland</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="IN">India</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="ID">Indonesia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="IE">Ireland</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="IL">Israel</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="IT">Italy</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="JM">Jamaica</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="JP">Japan</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="JO">Jordan</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="KZ">Kazakhstan</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="KE">Kenya</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="KI">Kiribati</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="KW">Kuwait</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="KG">Kyrgyzstan</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="LA">Laos</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="LV">Latvia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="LS">Lesotho</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="LI">Liechtenstein</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="LT">Lithuania</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="LU">Luxembourg</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MK">Macedonia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MG">Madagascar</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MW">Malawi</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MY">Malaysia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MV">Maldives</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="ML">Mali</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MT">Malta</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MH">Marshall Islands</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MQ">Martinique</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MR">Mauritania</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MU">Mauritius</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="YT">Mayotte</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="FM">Micronesia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MD">Moldova</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MC">Monaco</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MN">Mongolia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="ME">Montenegro</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MS">Montserrat</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MA">Morocco</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="MZ">Mozambique</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="NA">Namibia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="NR">Nauru</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="NP">Nepal</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="NL">Netherlands</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="NC">New Caledonia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="NZ">New Zealand</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="NI">Nicaragua</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="NE">Niger</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="NG">Nigeria</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="NU">Niue</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="NF">Norfolk Island</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="NO">Norway</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="OM">Oman</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="PW">Palau</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="PA">Panama</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="PG">Papua New Guinea</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="PY">Paraguay</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="PE">Peru</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="PH">Philippines</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="PN">Pitcairn Islands</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="PL">Poland</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="PT">Portugal</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="QA">Qatar</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="RE">Réunion</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="RO">Romania</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="RU">Russia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="RW">Rwanda</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="WS">Samoa</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SM">San Marino</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="ST">São Tomé &amp; Príncipe</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SA">Saudi Arabia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SN">Senegal</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="RS">Serbia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SC">Seychelles</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SL">Sierra Leone</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SG">Singapore</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SK">Slovakia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SI">Slovenia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SB">Solomon Islands</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SO">Somalia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="ZA">South Africa</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="KR">South Korea</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="ES">Spain</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="LK">Sri Lanka</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SH">St. Helena</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="KN">St. Kitts &amp; Nevis</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="LC">St. Lucia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="PM">St. Pierre &amp; Miquelon</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="VC">St. Vincent &amp; Grenadines</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SR">Suriname</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SJ">Svalbard &amp; Jan Mayen</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SZ">Swaziland</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="SE">Sweden</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="CH">Switzerland</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="TW">Taiwan</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="TJ">Tajikistan</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="TZ">Tanzania</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="TH">Thailand</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="TG">Togo</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="TO">Tonga</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="TT">Trinidad &amp; Tobago</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="TN">Tunisia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="TM">Turkmenistan</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="TC">Turks &amp; Caicos Islands</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="TV">Tuvalu</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="UG">Uganda</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="UA">Ukraine</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="AE">United Arab Emirates</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="GB">United Kingdom</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="UY">Uruguay</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="VU">Vanuatu</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="VA">Vatican City</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="VE">Venezuela</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="VN">Vietnam</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="WF">Wallis &amp; Futuna</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="YE">Yemen</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="ZM">Zambia</MenuItem>
                                                        <MenuItem key={Math.random().toString().replace("0.", "")} value="ZW">Zimbabwe</MenuItem>


                                                    </Select>
                                                    {(errors.country && !!touched.country) && <FormHelperText>{t('you_must_choose_a_country'??'',{ ns: namespaces.mistakes})}</FormHelperText>}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                                <Grid md={4} xs={16} display="flex" alignSelf="self-end" justifySelf="left" className="inputwidth">
                                    <FormControl className="inputwidth FormControl">
                                        <Grid container display="flex"  columns={12} >
                                            <Grid md={12} sm={16} xs={16}>
                                                <FormLabel id="label-id-select-hotel-cruise-line">{t('city'??'',{ ns: namespaces.client_information})}</FormLabel>
                                                <FormControl sx={{mt: 1, minWidth: '100%'}}>
                                                    <TextField
                                                        id="City"
                                                        name={"city"}
                                                        error={!!errors.city && !!touched.city}
                                                        helperText={(errors.city && touched.city)?t('you_must_choose_a_city'??'',{ ns: namespaces.mistakes}):''}
                                                        onChange={(event)=>{
                                                            setFieldValue("city", event.currentTarget.value??'')
                                                        }}
                                                        value={values.city}
                                                        variant="standard"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                                <Grid md={12} xs={16} display="flex" alignSelf="self-end" justifySelf="left" className="inputwidth">
                                    <FormControl  className="inputwidth FormControl" >
                                        <Grid container display="flex"  columns={12} >
                                            <Grid md={12} sm={16} xs={16}>
                                                <FormControl sx={{mt: 4, minWidth: '100%'}} error={!!errors.terms_conditions &&!!touched.terms_conditions}>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        name="radio-buttons-group"
                                                        value={values.terms_conditions?'checked':''}
                                                    >
                                                        <FormControlLabel
                                                            className="inputRadio"
                                                            onChange={(event, checked)=>{
                                                                setFieldValue("terms_conditions", checked)
                                                            }}
                                                            value={'checked'}
                                                            control={<Radio />}
                                                            label={t('confirm'??'',{ ns: namespaces.client_information})} />
                                                    </RadioGroup>

                                                    {
                                                        (errors.terms_conditions&&!!touched.terms_conditions) && <FormHelperText>{t('checked_confirm'??'',{ ns: namespaces.mistakes})}</FormHelperText>
                                                    }
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                }
                {
                    activeStep === 2 &&<Grid md={11}>
                        <AddOns/>
                    </Grid>
                }
                {
                    activeStep === 3 &&  <Grid md={11}  xs={16}>
                        {
                          <>
                              <Grid md={12} display="flex" justifyContent="start" alignItems="flex-end">
                                  <div className="AshleySemBold txt-25">{t('details_of_the_reservation'??'',{ ns: namespaces.payment_process})}</div>
                              </Grid>
                              {
                                  items.map((item: IPackagesItems,index:number)=>{
                                      return<React.Fragment key={`fragment-payment-${index}`}>

                                          <CardPackagePayment
                                              key={index}
                                              id_tour={item.sku}
                                              type={item.type}
                                              amount={item.amount}
                                              paragrahp_1={item.name}
                                              date_reservation={item.date_operation}
                                              adult={+item.adult}
                                              child={+item.children}
                                              transfer={item.idTransporte!==null}
                                          />
                                      </React.Fragment>

                                  })
                              }
                          </>

                        }

                    </Grid>
                }
                {
                    (activeStep === 4 && location.pathname==='/final-book') && <FinalBook/>
                }
                <Grid md={5} xs={16}>

                    <Grid container spacing={2} columns={12} className="cont-detail">
                        <PurchaseDetail isVisibleFinalBook={location.pathname!=='/final-book'} isVisibleCoupon={location.pathname==='/payment-method'} coupon={coupon} promotion={promotion} isVisible={ activeStep === 3}></PurchaseDetail>
                        {
                            (activeStep !== 3 && location.pathname!=='/final-book') &&
                            <Grid md={12} sm={16} display="flex" justifyContent="right" alignItems="center" className="contbtnContinue">

                                <Button variant="contained"  type={'button'}
                                        onClick={() => {

                                            if(resume_cart_contain_at_least_one_service||folio!==''){
                                                activeStep===1? submitForm().then( ): handleNext()
                                            }

                                        }}
                                        className="btnContinue">
                                    {t('continue'??'',{ ns: namespaces.purchase_details})}
                                </Button>
                            </Grid>
                        }
                    </Grid>

                </Grid>
                <Grid md={12}>
                </Grid>

            </Grid>
        </Container>
    );
}
